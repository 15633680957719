import {
    store
} from "../../../../../main"
import {
    DOC_ASSISTANT_PERMISSIONS,
    PRESENT_MASTER_PERMISSIONS,
    SLIDES_GALLERY_PERMISSIONS,
} from "./permissions.gh"


export const DOC_ASSISTANT_BUTTONS = {
    DOC_ASSISTANT_MAIN: {
        name: "Doc Assistant ",
        description: "Will open Doc Assistant main view",
        icon: "mdi-mdi-file-sign",
        color: "#3397bf",
        on: "onDocAssistant",
        permission: DOC_ASSISTANT_PERMISSIONS.ALLOW_USE_DOC_ASSISTANT,
        handler: (props) => store.dispatch('DocAssistantStore/openDocAssistant', props)
    },
    PROPOSAL_ASSISTANT: {
        name: "Proposal Assistant",
        description: "Will open proposal Assistant",
        icon: "mdi-chart-timeline",
        color: "#23842a",
        on: "onProposalAssistantOpen",
        permission: DOC_ASSISTANT_PERMISSIONS.ALLOW_USE_PROPOSAL_ASSISTANT,
        handler: (props) => store.dispatch('DocAssistantStore/openProposalAssistant', props)
    }
}

export const SLIDES_BUTTONS = {
    LIST: {
        name: "Slides Gallery",
        description: "Will open Slides Gallery",
        icon: "mdi-presentation-play",
        color: "#23842a",
        on: "onSlidesGallery",
        permission: SLIDES_GALLERY_PERMISSIONS.ALLOW_USE_SLIDES_GALLERY,
        handler: (props) => store.dispatch('SlidesStore/openSlidesGallery', props)
    },
    SAVE: {
        name: "Save",
        description: "Will save new Slide",
        icon: "mdi-check",
        color: "#23842a",
        on: "onSlideSave",
        permission: SLIDES_GALLERY_PERMISSIONS.ALLOW_UPLOAD_NEW_SLIDES
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will open Slide Uploader",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onSlidesUpload",
        permission: SLIDES_GALLERY_PERMISSIONS.ALLOW_UPLOAD_NEW_SLIDES,
        handler: (props) => store.dispatch('SlidesStore/openSlideCreation', props)
    },
}



export const PRESENT_MASTER_BUTTONS = {
    MAIN: {
        name: "Present Master",
        description: "Will open Present Master",
        icon: "mdi-gift-open-outline",
        color: "#23842a",
        on: "onPresentMaster",
        permission: PRESENT_MASTER_PERMISSIONS.ALLOW_USE_PRESENT_MASTER,
        handler: (props) => store.dispatch('PresentMasterStore/openPresentMaster', props)
    },
    SAVE: {
        name: "Generate",
        description: "Will Init Presentation Generation",
        icon: "mdi-check",
        color: "#23842a",
        on: "onPresentationGeneration",
        permission: PRESENT_MASTER_PERMISSIONS.ALLOW_USE_PRESENT_MASTER,
    }
}