<template>
  <v-expand-transition>
    <v-row class="pagination-row" v-if="pShow && value.total > total">
      <v-col class="justify-center d-flex flex-column align-center">
        <v-btn @click="() => $emit('onLoadMore')" icon>
          <v-icon color="secondary"> mdi-chevron-double-down</v-icon>
        </v-btn>
        <span>Load more...</span>
      </v-col>
    </v-row>
  </v-expand-transition>
  <!--  -->
</template>



<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
    showDelay: {
      type: Number,
      default: 300,
    },
    value: {
      default: () => ({}),
    },
  },
  data() {
    return {
      pShow: this.show,
      showTimeout: undefined,
    };
  },

  methods: {},
  watch: {
    show(newVal) {
      if (this.showTimeout) clearTimeout(this.showTimeout);

      if (!newVal) this.pShow = newVal;
      else
        this.showTimeout = setTimeout(() => {
          this.pShow = newVal;
        }, this.showDelay);
    },
  },
};
</script>
    
    
<style lang="scss">
.pagination-row {
  transform-origin: center top 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
  width: calc(100% - 4px);
  border-radius: 25px 25px 0 0;
}
</style>