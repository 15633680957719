<template>
  <AView
    :value="value"
    :actions="actions"
    :hasFilters="!!filter.search"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    :payload="{
      from: value,
    }"
  >
    <template v-slot:content="{}">
      <v-row>
        <v-col cols="12">
          <FormSection
            right
            :label="'Slides'"
            :icon="value.view.display.icon"
            :actions="actions"
            :editable="!!actions.length"
            @action="(action) => action.handler({ from: value })"
          ></FormSection>
        </v-col>
      </v-row>

      <v-row class="scroll-row d-flex flex-grow-1">
        <v-col cols="12" class="pa-0 ma-0 d-flex flex-column flex-grow-1">
          <v-virtual-scroll
            class="pa-0 ma-0"
            :bench="5"
            :items="slidesChunks"
            height="630"
            item-height="260"
            @scroll="onScroll"
          >
            <template v-slot:default="{ item }">
              <v-row>
                <v-col
                  class="pa-2 ma-0"
                  cols="6"
                  v-for="slide in item"
                  :key="slide.id"
                >
                  <v-card class="mx-auto bg-grey-gradient slide-preview-card">
                    <v-card-title>
                      {{ slide.name }} <v-spacer></v-spacer>
                      <v-btn @click="onFileOpen(slide.file)" icon
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </v-card-title>
                    <v-card-subtitle> {{ slide.file.name }} </v-card-subtitle>

                    <div class="slide-preview-container">
                      <img
                        class="slide-preview"
                        referrerpolicy="no-referrer"
                        :src="slide.file.preview"
                        :lazy-src="slide.file.preview"
                      />
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-virtual-scroll>
        </v-col>
      </v-row>
      <Pagination
        :value="pagination"
        :total="slides.length"
        :show="showMore"
        @onLoadMore="
          $store.dispatch('SlidesStore/GetSlidesList', {
            next: true,
          })
        "
      ></Pagination>
    </template>

    <template v-slot:actions="{}">
      <SlidesFilter v-model="filter"> </SlidesFilter>
    </template>
  </AView>
</template>
      
      
      
    <script>
import { mapState } from "vuex";
import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import Pagination from "../../../../../components/wad/atoms/common/Pagination.vue";
//   import SlidesFilter from "../../../../../components/wad/molecules/knowledgeBase/slides/SlidesFilter.vue";
import { PermissionsHelper } from "../../../../../components/helpers/permissions.helper";
import { SLIDES_BUTTONS } from "../constants/actions.gh";
import _ from "lodash";

export default {
  name: "SlidesList",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    FormSection,
    Pagination,
    //   SlidesFilter,
  },
  computed: {
    ...mapState("SlidesStore", ["slides", "pagination"]),
    actions: function () {
      return PermissionsHelper.getActions(
        SLIDES_BUTTONS.CREATE_NEW,
        this.value.permissions
      );
    },
    slidesChunks: function () {
      return this.slides && this.slides.length ? _.chunk(this.slides, 2) : [];
    },
  },
  async created() {
    this.$store.dispatch("SlidesStore/GetSlidesList", {
      reset: true,
    });
  },
  data() {
    return {
      filter: {},
      showMore: false,
    };
  },
  methods: {
    onHover() {},
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },
    onFileOpen(file) {
      file.u_type = "documents";
      this.$store.dispatch("FileStore/openFileDetails", {
        from: this.value,
        file,
      });
    },
  },
  watch: {
    filter: {
      handler(newVal) {
        this.$store.dispatch("SlidesStore/GetSlidesList", {
          filter: newVal,
          reset: true,
        });
      },
      deep: true,
    },
  },
};
</script>
      
      
      
  <style scoped lang="scss" >
.scroll-row {
  height: calc(100% - 110px);
}

.slide-preview-card {
  border-radius: 4px;

  &::after{
  border-radius: 4px;

  }

  .slide-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    .slide-preview {
      max-height: 130px;
      border-radius: 4px;
    }
  }
}
</style>